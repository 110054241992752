<template>
  <component :is="WelcomeComponent" />
</template>

<script>
import { useUserStore } from "@/stores/user";
import { computed, defineAsyncComponent } from "vue";

export default {
  setup() {
    const userStore = useUserStore();

    const WelcomeComponent = computed(() => {
      const userType = userStore.userType;
      if (userType === "client") {
        return defineAsyncComponent(() =>
          import(
            "@/views/Auth/Registration/Client/RegistrationClientWelcome.vue"
          )
        );
      }
      if (userType === "contractor") {
        return defineAsyncComponent(() =>
          import(
            "@/views/Auth/Registration/Contractor/RegistrationContractorWelcome.vue"
          )
        );
      }
      return null;
    });

    return {
      WelcomeComponent,
    };
  },
};
</script>
